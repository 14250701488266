@font-face {
  font-family: 'iAWriterQuattroV';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(assets/fonts/iAWriterQuattroV.ttf) format('truetype');
}

@font-face {
  font-family: 'iAWriterMonoV';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(assets/fonts/iAWriterMonoV.ttf) format('truetype');
}

.App {
  font-family: 'iAWriterQuattroV', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
button {
  font-family: 'iAWriterMonoV' !important;
  color: #dd7700;
}
